.tip {
    text-align: center;
}

.input {
    background-color: #EAEAEA;
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 18px;
    margin-bottom: 10px;
}

.pay-body {
    padding: 15px;
    padding-top:5px;	
}

.input-label {
    font-size: 14px;
    color: #B5B5B5;
    margin-bottom: 5px;
}

#qrcode{
    width: 150px;
    height: 150px;
    margin: 0 auto;
}

.showwindow {
    color: rgba(21, 120, 229, 1);
}
.steps-line{margin-bottom: 1px}