html,body,#root{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
}
.bank-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    padding-bottom: 10px;
    padding-top: 10px;
}
.btn{
    background-color: #4E6EF2;
    padding: 15px;
    text-align: center;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    font-size: 20px;

}
.btn-2{
    font-size: 14px;
}
.btn-cancel{

    background-color: "#ccc";
    color: "#666";
}
.msg-b{
    position: fixed;
   width: 100%;
   height: 100%;
   top: 0px;
   z-index: 999999;
   display: flex;
   align-items: center;
   justify-content: center;

}
.msg-b-text{
    
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    max-width:300px;
    padding: 10px;

    border-radius:10;

}
.disabled{
    pointer-events: none;
    opacity: 0.3;
}
.check {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 50px;
    background: green;
    border-radius: 25px;
}
.check::after {
    content: "";
    position: absolute;
    left: 12px;
    top: 10px;
    width: 50%;
    height: 25%;
    border: 2px solid #fff;
    border-radius: 1px;
    border-top: none;
    border-right: none;
    background: transparent;
    transform: rotate(-45deg);
}
.fork {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: red;
    display: inline-block;
    position: relative;
    padding: 10px;
}

.g-right {
    display: inline-block;
    width: 30px;
    height: 4px;
    background: #fff;
    line-height: 0;
    font-size: 0;
    vertical-align: middle;
    transform: rotate(45deg);
    position: absolute;
    top: 25px;
    left: 15px;
}

.g-right:after {
    content: "/";
    display: block;
    width: 30px;
    height: 4px;
    background: #fff;
    transform: rotate(-90deg);
}

.spinner {
    /* margin: 100px auto; */
    width: 50px;
    height: 60px;
    text-align: center;
    font-size: 10px;
  }

  .loading{
      position: relative;
      display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .spinner>div {
    background-color: #4E6EF2;
    height: 100%;
    width: 6px;
    display: inline-block;

    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
  }

  .spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }

  .spinner .rect3 {
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
  }

  .spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }

  .spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }

  @-webkit-keyframes stretchdelay {

    0%,
    40%,
    100% {
      -webkit-transform: scaleY(0.4)
    }

    20% {
      -webkit-transform: scaleY(1.0)
    }
  }

  @keyframes stretchdelay {

    0%,
    40%,
    100% {
      transform: scaleY(0.4);
      -webkit-transform: scaleY(0.4);
    }

    20% {
      transform: scaleY(1.0);
      -webkit-transform: scaleY(1.0);
    }
  }